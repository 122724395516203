import { unref } from 'vue'

export default (_rit) => {
  const rit = unref(_rit)
  if (rit.is_betaalbaar_online) return 'ONLINE/MOLLIE'
  if (rit.is_betaalterminal) return 'BETAALTERMINAL'
  if (rit.is_overschrijving_gewenst) return 'BANK OVERSCHRIJVING'
  if (rit.is_factuur_nodig) return 'OP FACTUUR'
  return 'CASH'
}
